import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PiniaPersistedState from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import '@fontsource/nunito-sans'
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'

export const showToast = (message, options = {}) => {
    Toastify({
        text: message,
        duration: options.duration || 3000,
        close: options.close || false,
        gravity: options.gravity || 'top',
        position: options.position || 'right',
        ... options
    }).showToast();
};

const app = createApp(App)
const pinia = createPinia()
pinia.use(PiniaPersistedState)

app.use(pinia).use(router).mount('#app')
