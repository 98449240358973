<template>
  <div id="app">
    <Navbar/>
    <SideBar v-if="isLoggedIn"/>
    <router-view/>
  </div>
</template>

<script>
  import router from '@/router';
  import { useMainStore } from '@/stores/mainStore';
  import Navbar from '@/components/layout/NavbarComponent.vue';
  import SideBar from '@/components/layout/SidebarComponent.vue';
  import { showToast } from '@/main.js';

  export default {
    data() {
      return {
        isLoggedIn: '',
        toastMessage: '',
        toastColor: '',
        toastTextColor: '',
        toastBorderColor: ''
      }
    },

    components: {
      Navbar,
      SideBar
    },

    computed: {
      mainStore() {
        return useMainStore();
      },
      loggedInStatus() {
        this.isLoggedIn = this.mainStore.isLoggedIn; // Get isLoggedIn state from mainStore
      }
    },

    mounted() {
      this.loggedInStatus;
      const tokenExpirationTime = localStorage.getItem('tokenExpiration');
      const currentTime = new Date().getTime();
      const logoutRemainingTime = tokenExpirationTime - currentTime;
      setTimeout(() => {
        router.push({ name: 'home' });
        this.mainStore.logout();
      }, logoutRemainingTime);
    },

    methods: {
      notifyUser() {
        showToast(
          this.toastMessage,
          {
            style: {
              borderRadius: '15px',
              marginTop: '650px',
              background: this.toastColor,
              textShadow: '0 0 10px #000',
              textAlign: 'center',
              color: this.toastTextColor,
              border: this.toastBorderColor
            },
            position: 'center',
            duration: 3000
          }
        )
      },

      notificationTrigger(type, message) {
        if (type === 'success') {
          this.toastColor = 'linear-gradient(90deg, rgba(47,47,47,1) 0%, rgba(33,33,33,1) 50%, rgba(47,47,47,1) 100%)';
          this.toastTextColor = '#ffc107';
          this.toastBorderColor = '1px solid #ffc107';
        } else if (type === 'error') {
          this.toastColor = 'linear-gradient(90deg, rgba(219,164,0,1) 0%, rgba(255,193,7,1) 50%, rgba(219,164,0,1) 100%)';
          this.toastTextColor = '#212121';
          this.toastBorderColor = '1px solid #212121';
        };
        this.toastMessage = message;
        this.notifyUser(this.toastMessage);
      },

      formatRUT(rut) {
        return rut.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
    }
  }
</script>

<style lang="scss">
  @import '@/assets/sass/main';

  #app {
    font-family: "Nunito Sans";
    font-optical-sizing: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: $vp100;
    height: $vp100
  }

  body {
    background: url('./assets/img/globals/bg-carbon-fiber.jpg');
    width: $vp100;
    height: $vp100
  }
</style>
