import { defineStore } from  'pinia';
import axios from 'axios';

export const useMainStore = defineStore('main', {
    state: () => ({
        token: null,
        role_id: null,
        email: null,
        isRutDvFilled: false,
        entityRut: null,
        entityId: null,
        isNavbarVisible: true,
        isLoggedIn: false,
        reloaded: false,
        homeReloaded: false
    }),

    getters: {
        currentRole: (state) => {
            switch (state.role_id) {
                case 1: return 'Developer';
                case 2: return 'Manager';
                case 3: return 'User';
                case 4: return 'Customer';
                case 5: return 'Test';
                default: return 'Invalid Role';
            }
        },
    },

    actions: {
        async login(loginData) {
            try {
                const response = await axios.post('https://api.fusioncar.cl/entity/login',
                    loginData
                );

                // Set states data
                this.isLoggedIn = true;
                this.homeReloaded = false;
                this.token = response.data.token;
                this.role_id = response.data.entity.role_id;
                this.email = response.data.entity.email;
                this.entityRut = response.data.entity.rut;
                this.entityId = response.data.entity.id;
                this.isRutDvFilled = response.data.entity.is_rut_dv_filled

                // Return data to client
                return response.data
            } catch(error) {
                const errorMessage =  error.response?.data || error.message;
                this.logError(errorMessage);
                throw { message: error.response.data.msg, status: error.response.status };
            };
        },

        logout() {
            this.token = null;
            this.email = null;
            this.role_id = null;
            this.isLoggedIn = false;
            this.reloaded = false;
            this.userRut = null;
            this.userId = null;
            localStorage.removeItem('tokenExpiration');
        },

        setNavbarVisibility(visibility) {
            this.isNavbarVisible = visibility;
        },
        
        setLoginState(isLoggedIn) {
            this.isLoggedIn = isLoggedIn;
        },
          
        setToken(token) {
            this.token = token;
        },

        setRoleId(role_id) {
            this.role_id = role_id;
        },
          
        setEmail(email) {
            this.email = email;
        }
    },

    persist: {
        paths: ['token', 'role_id', 'email', 'isLoggedIn', 'reloaded', 'homeReloaded'],
        storage: sessionStorage,
    }
});
