<template>
  <div class="home">
    <mainCarousel/>
  </div>
</template>

<script>
  import { useMainStore } from '@/stores/mainStore';
  import mainCarousel from '@/components/layout/main/CarouselComponent.vue'

  export default {
    name: 'Home',

    data() {
      return {
        homeReloaded: ''
      }
    },

    components: {
      mainCarousel
    },

    mounted() {
      const mainStore = useMainStore();
      const token = mainStore.token;
      this.homeReloaded = mainStore.homeReloaded;

      if (!token && !this.homeReloaded) {
        mainStore.homeReloaded = true;
        mainStore.isNavbarVisible = true;
        location.reload();
      };
    }
  }
</script>

<style lang="sass">
  @import '@/assets/sass/main'

  * 
    color: $color-light

  .home
    height: $vp100
    
</style>